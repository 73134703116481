import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import Img from 'gatsby-image';
import clsx from 'clsx';
import {Event} from '../../GAClickEvent';
import '../../../assets/css/header.css';
import useScrollHandler from './useScrollHandler';
import useMediaIsDesktop from './useMediaIsDesktop';
import useStaticQueryForLogo from './useStaticQueryForLogo';
import {NAVBAR_TRANSPARENT, NAVBAR_WHITE} from './constants';
import {useEffect} from 'react';
import axios from 'axios';

const activeLinkStyle = {
  color: '#30aefc',
};

const Header = ({position}) => {
  const [showCollapsedMenu, setShowCollapsedMenu] = useState(false);
  const [showFraudBanner, setShowFraudBanner] = useState(false);
  const headerRef = useRef();
  useScrollHandler(headerRef, position, showFraudBanner);
  useMediaIsDesktop({query: '(min-width: 992px)'}, headerRef);
  const data = useStaticQueryForLogo();

  //creating function to load ip address from the API
  const getData = async () => {
    fetch('https://api.ipify.org/?format=json')
      .then(response1 => response1.json())
      .then(data => {
        const ip = data.ip;
        const url = 'https://ipinfo.io/' + ip + '?token=156be8a8ff5b29';
        fetch(url)
          .then(response2 => response2.json())
          .then(data2 => {
            if (data2.country === 'HK') {
              setShowFraudBanner(true);
              localStorage.setItem('b', true);
            }
          });
      });
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);

  const toggleMenu = () => {
    setShowCollapsedMenu(preVal => !preVal);
  };

  return (
    <>
      {showFraudBanner ? (
        <div className="contact-disclaimer">
          <div>
            Fraudulent Activity Disclaimer! tintash.com and tintash.no are our only websites.&nbsp;
            <a href="/fraud-disclaimer" rel="noopener noreferrer">
              Learn more
            </a>
            .
          </div>
          <div
            onClick={() => {
              setShowFraudBanner(false);
              localStorage.setItem('b', false);
            }}
          >
            <i className="fa fa-times" />
          </div>
        </div>
      ) : null}

      <header
        ref={headerRef}
        className={clsx('nav-position', {
          [NAVBAR_WHITE]: position === 'fixed',
          [NAVBAR_TRANSPARENT]: position === 'sticky',
        })}
      >
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12">
              <nav id="nav" className="navbar navbar-expand-lg navbar-light">
                <Link className="navbar-brand" to="/">
                  <div id="logo-sticky">
                    <Img
                      style={{display: 'inherit'}}
                      imgStyle={{objectFit: 'contain'}}
                      placeholderStyle={{objectFit: 'contain'}}
                      objectFit="contain"
                      objectPosition="50% 50%"
                      width="140"
                      id="logo-sticky"
                      className="img-fluid navbar-logo"
                      alt="Tintash Logo"
                      fixed={data.tintash.childImageSharp.fixed}
                      fadeIn={false}
                      loading="eager"
                    />
                  </div>
                  <div id="logo">
                    <Img
                      style={{display: 'inherit'}}
                      imgStyle={{objectFit: 'contain'}}
                      placeholderStyle={{objectFit: 'contain'}}
                      objectFit="contain"
                      objectPosition="center"
                      width="140"
                      id="logo"
                      className="img-fluid navbar-logo"
                      alt="Tintash Logo"
                      fixed={data.tintash_hd.childImageSharp.fixed}
                      fadeIn={false}
                      loading="eager"
                    />
                  </div>
                </Link>

                <button
                  className="navbar-toggler"
                  onClick={toggleMenu}
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarToggler"
                  aria-controls="navbarTogglerDemo03"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  className={`collapse navbar-collapse ${showCollapsedMenu ? 'show' : ''}`}
                  id="navbarToggler"
                >
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item ml-lg-0 ml-md-3 mr-md-3 mt-md-0 ml-sm-0 mr-sm-0 mt-sm-0 latoBold">
                      <Link
                        activeStyle={activeLinkStyle}
                        onClick={() => Event('About Us', 'About Us button is clicked', 'Header')}
                        className="nav-link nav-link-text"
                        to="/about/"
                      >
                        About Us
                      </Link>
                    </li>
                    <li className="nav-item ml-lg-0 ml-md-3 mr-md-3 mt-md-0 ml-sm-0 mr-sm-0 mt-sm-0">
                      <Link
                        activeStyle={activeLinkStyle}
                        onClick={() => Event('Services', 'Services button is clicked', 'Header')}
                        className="nav-link nav-link-text"
                        to="/services/"
                      >
                        Services
                      </Link>
                    </li>
                    <li className="nav-item ml-lg-0 ml-md-3 mr-md-3 mt-md-0 ml-sm-0 mr-sm-0 mt-sm-0">
                      <Link
                        activeStyle={activeLinkStyle}
                        onClick={() => Event('Verticals', 'Verticals button is clicked', 'Header')}
                        className="nav-link nav-link-text"
                        to="/verticals/"
                      >
                        Verticals
                      </Link>
                    </li>
                    <li className="nav-item ml-lg-0 ml-md-3 mr-md-3 mt-md-0 ml-sm-0 mr-sm-0 mt-sm-0">
                      <Link
                        activeStyle={activeLinkStyle}
                        onClick={() => Event('Portfolio', 'Portfolio button is clicked', 'Header')}
                        className="nav-link nav-link-text"
                        to="/portfolio/"
                      >
                        Portfolio
                      </Link>
                    </li>

                    <li className="nav-item ml-lg-0 ml-md-3  mt-md-0 ml-sm-0 mr-sm-0 mt-sm-0">
                      <Link
                        onClick={() =>
                          Event('Careers Button', 'Careers button is clicked', 'Header')
                        }
                        className="nav-link nav-link-text"
                        style={{color: '#0EC573'}}
                        to="https://tintash.zohorecruit.com/jobs/new-openings"
                      >
                        Careers
                      </Link>
                    </li>
                    <li className="nav-item ml-lg-0 ml-md-3  mt-md-0 ml-sm-0 mr-sm-0 mt-sm-0">
                      <Link
                        onClick={() =>
                          Event('Contact Us', 'Contact Us button is clicked', 'Header')
                        }
                        className="nav-link nav-link-text"
                        style={{color: '#0EC573'}}
                        to="/contact/"
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

Header.propTypes = {
  position: PropTypes.oneOf(['sticky', 'fixed']),
};

Header.defaultProps = {
  position: 'sticky',
};

export default Header;
